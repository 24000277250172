exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-akins-laws-js": () => import("./../../../src/pages/akins_laws.js" /* webpackChunkName: "component---src-pages-akins-laws-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-cubedrive-js": () => import("./../../../src/pages/projects/cubedrive.js" /* webpackChunkName: "component---src-pages-projects-cubedrive-js" */),
  "component---src-pages-projects-delfi-real-js": () => import("./../../../src/pages/projects/delfi-real.js" /* webpackChunkName: "component---src-pages-projects-delfi-real-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-rba-js": () => import("./../../../src/pages/projects/rba.js" /* webpackChunkName: "component---src-pages-projects-rba-js" */),
  "component---src-pages-projects-satdrive-js": () => import("./../../../src/pages/projects/satdrive.js" /* webpackChunkName: "component---src-pages-projects-satdrive-js" */),
  "component---src-pages-projects-sso-space-debris-js": () => import("./../../../src/pages/projects/sso-space-debris.js" /* webpackChunkName: "component---src-pages-projects-sso-space-debris-js" */)
}

